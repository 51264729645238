/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */

import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import AttendantCreateMaintenance from '@hkm/components/Attendant/CreateMaintenance/AttendantCreateMaintenance';
import AttendantRoomDetails from '@hkm/components/Attendant/RoomDetails/AttendantRoomDetails';
import AttendantRoomList from '@hkm/components/Attendant/RoomList/AttendantRoomList';
import { AttendantAssignmentsDictionaries } from '@hkm/components/Attendant/shared/config/attendantDictionaries';
import { AttendantEffectiveValues } from '@hkm/components/Attendant/shared/config/attendantEffeectiveValues';
import { AttendantViewPermissionsConfig } from '@hkm/components/Attendant/shared/config/attendantViewPermissionsConfig';
import AttendantAssignmentSheetSummary from '@hkm/components/Attendant/SheetSummary/AttendantSheetSummary';
import HousekeepingFilters from '@hkm/components/Housekeeping/AdvancedFilters/HousekeepingAdvancedFilters';
import BatchContainer from '@hkm/components/Housekeeping/Batch/BatchContainer';
import HousekeepingCreateMaintenance from '@hkm/components/Housekeeping/CreateMaintenance/HousekeepingCreateMaintenance';
import HousekeepingDashboardContainer from '@hkm/components/Housekeeping/Dashboard/HousekeepingDashboardContainer';
import { HousekeepingDetailsDictionaries } from '@hkm/components/Housekeeping/Details/config/housekeepingDetailsDictionaries';
import HousekeepingDetailsContainer from '@hkm/components/Housekeeping/Details/HousekeepingDetailsContainer';
import HelpContainer from '@hkm/components/Housekeeping/Help/HelpContainer';
import { HousekeepingDashboardDictionaries } from '@hkm/components/Housekeeping/shared/config/housekeepingDashboardDictionaries';
import { HousekeepingEffectiveValues } from '@hkm/components/Housekeeping/shared/config/housekeepingEffectiveValues';
import { HousekeepingManagePermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingManagePermissionsConfig';
import { HousekeepingViewPermissionsConfig } from '@hkm/components/Housekeeping/shared/config/housekeepingViewPermissionsConfig';
import { HousekeepingAdvancedFiltersDictionaries } from '@hkm/components/Housekeeping/shared/filters/housekeepingAdvancedFiltersDictionaries';
import { MaintenanceManagePermissionsConfig } from '@hkm/components/Maintenance/config/maintenanceManagePermissionsConfig';
import { MaintenanceDashboardDictionaries } from '@hkm/components/Maintenance/shared/config/maintenanceDashboardDictionaries';
import RouteComposeWrapper from '@hkm/components/shared/Routes/RouteComposeWrapper/RouteComposeWrapper';
import TaskManagementDashboardFilters from '@hkm/components/TaskManagement/Dashboard/DashboardFilters/DashboardFilters';
import TaskManagementDashboard from '@hkm/components/TaskManagement/Dashboard/TaskManagementDashboard';
import { TaskManagementFeatureToggles } from '@hkm/components/TaskManagement/shared/config/taskManagementFeatureToggles';
import { TaskManagementViewPermissionsConfig } from '@hkm/components/TaskManagement/shared/config/taskManagementViewPermissionsConfig';
import { requireTasksDictionaries } from '@hkm/components/TaskManagement/shared/routeGuards/requireTasksDictionaries';
import TaskPreview from '@hkm/components/TaskManagement/TaskPreview/TaskPreview';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useMobileFeaturesContext } from '@hkm/features/app/store/features/context';
import { ErrorPage, ErrorPageType } from '@hkm/features/errors/pages/Error';
import { MicroFrontendPage } from '@hkm/features/microFrontends/pages/container/MicroFrontendPage';
import { HomePage } from '@hkm/features/root/pages/Home';
import { requireAttendantSheet } from '@hkm/utils/routeGuards/requireAttendantSheet';
import { requireAuth } from '@hkm/utils/routeGuards/requireAuth';
import { requireDictionaries } from '@hkm/utils/routeGuards/requireDictionaries';
import { requireEffectiveValues } from '@hkm/utils/routeGuards/requireEffectiveValues';
import { requireFeatureToggles } from '@hkm/utils/routeGuards/requireFeatureToggles';
import { requirePermission } from '@hkm/utils/routeGuards/requirePermission';
import { compose } from 'redux';

import './Router.scss';

export const AppRouter = (): JSX.Element => {
  const { housekeeping, task } = useMobileFeaturesContext();

  return (
    <Routes>
      <Route
        path={APP_ROUTES.HOUSEKEEPING.DASHBOARD}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(HousekeepingEffectiveValues),
              requirePermission(...HousekeepingViewPermissionsConfig),
              requireDictionaries(HousekeepingDashboardDictionaries)
            )(HousekeepingDashboardContainer)}
          />
        }
      />
      <Route
        path={APP_ROUTES.HOUSEKEEPING.BATCH}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(HousekeepingEffectiveValues),
              requirePermission(...HousekeepingManagePermissionsConfig),
              requireDictionaries(HousekeepingDetailsDictionaries)
            )(BatchContainer)}
          />
        }
      />
      <Route
        path={APP_ROUTES.HOUSEKEEPING.DETAILS}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(HousekeepingEffectiveValues),
              requirePermission(...HousekeepingViewPermissionsConfig),
              requireDictionaries(HousekeepingDetailsDictionaries)
            )(HousekeepingDetailsContainer)}
          />
        }
      />
      <Route
        path={APP_ROUTES.HOUSEKEEPING.FILTERS}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(HousekeepingEffectiveValues),
              requirePermission(...HousekeepingViewPermissionsConfig),
              requireDictionaries(HousekeepingAdvancedFiltersDictionaries)
            )(HousekeepingFilters)}
          />
        }
      />
      <Route
        path={APP_ROUTES.HOUSEKEEPING.ADD_MAINTENANCE}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(HousekeepingEffectiveValues),
              requirePermission(...MaintenanceManagePermissionsConfig),
              requireDictionaries(MaintenanceDashboardDictionaries)
            )(HousekeepingCreateMaintenance)}
          />
        }
      />
      <Route
        path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(AttendantEffectiveValues),
              requirePermission(...AttendantViewPermissionsConfig),
              requireDictionaries(AttendantAssignmentsDictionaries),
              requireAttendantSheet
            )(AttendantAssignmentSheetSummary)}
          />
        }
      />
      <Route
        path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(AttendantEffectiveValues),
              requirePermission(...AttendantViewPermissionsConfig),
              requireDictionaries(AttendantAssignmentsDictionaries),
              requireAttendantSheet
            )(AttendantRoomList)}
          />
        }
      />
      <Route
        path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(AttendantEffectiveValues),
              requirePermission(...AttendantViewPermissionsConfig),
              requireDictionaries(AttendantAssignmentsDictionaries),
              requireAttendantSheet
            )(AttendantRoomDetails)}
          />
        }
      />
      <Route
        path={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ADD_MAINTENANCE}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireEffectiveValues(AttendantEffectiveValues),
              requirePermission(...AttendantViewPermissionsConfig),
              requireDictionaries(MaintenanceDashboardDictionaries),
              requireAttendantSheet
            )(AttendantCreateMaintenance)}
          />
        }
      />
      <Route
        key="task-management-dashboard-route"
        path={APP_ROUTES.TASK_MANAGEMENT.DASHBOARD}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requirePermission(...TaskManagementViewPermissionsConfig),
              requireFeatureToggles(TaskManagementFeatureToggles),
              requireTasksDictionaries()
            )(TaskManagementDashboard)}
          />
        }
      />
      <Route
        key="task-management-dashboard-filters-route"
        path={APP_ROUTES.TASK_MANAGEMENT.FILTERS}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requirePermission(...TaskManagementViewPermissionsConfig),
              requireFeatureToggles(TaskManagementFeatureToggles),
              requireTasksDictionaries()
            )(TaskManagementDashboardFilters)}
          />
        }
      />
      <Route
        key="task-management-task-details"
        path={APP_ROUTES.TASK_MANAGEMENT.TASK_PREVIEW}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requirePermission(...TaskManagementViewPermissionsConfig),
              requireFeatureToggles(TaskManagementFeatureToggles),
              requireTasksDictionaries()
            )(TaskPreview)}
          />
        }
      />
      <Route
        path={APP_ROUTES.HELP}
        element={
          <RouteComposeWrapper
            component={compose(
              requireAuth,
              requireDictionaries(HousekeepingDetailsDictionaries)
            )(HelpContainer)}
          />
        }
      />
      <Route
        path={`${APP_ROUTES.MICRO_FRONTEND}/housekeeping/*`}
        element={<MicroFrontendPage feature={housekeeping.view} />}
      />
      <Route
        path={`${APP_ROUTES.MICRO_FRONTEND}/task-management/*`}
        element={<MicroFrontendPage feature={task.view} />}
      />
      <Route
        path={APP_ROUTES.HOME}
        element={
          <RouteComposeWrapper component={compose(requireAuth)(HomePage)} />
        }
      />
      <Route
        path={APP_ROUTES.ACCESS_DENIED}
        element={<ErrorPage type={ErrorPageType.AccessDenied} />}
      />
      <Route path={'*'} element={<ErrorPage type={ErrorPageType.NotFound} />} />
    </Routes>
  );
};
